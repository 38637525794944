export default {
    light: {
      header: {
        bg: '#ff6666',
        text: '#fff'
      },
      launcher: {
        bg: '#ff6666'
      },
      messageList: {
        bg: '#fff'
      },
      sentMessage: {
        bg: '#aaaaaa',
        text: '#fff'
      },
      receivedMessage: {
        bg: '#eaeaea',
        text: '#222222'
      },
      userInput: {
        bg: '#fff',
        text: '#212121'
      },
      userList: {
        bg: '#fff',
        text: '#212121'
      }
    },
    dark: {
      header: {
        bg: '#ff6666',
        text: '#fff'
      },
      launcher: {
        bg: '#ff6666'
      },
      messageList: {
        bg: '#0c0c0c'
      },
      sentMessage: {
        bg: '#3d3d3d',
        text: '#fff'
      },
      receivedMessage: {
        bg: '#aaaaaa',
        text: '#fff'
      },
      userInput: {
        bg: '#0c0c0c',
        text: '#fff'
      },
      userList: {
        bg: '#0c0c0c',
        text: '#fff'
      }
    }
   
  }